<template>
  <div>
    <b-form-select
      id="seance-type-select"
      v-model="choice"
      @change="onChanged()"
      :disabled="choices.length === 1"
    >
      <b-form-select-option
        :value="elt"
        v-for="elt in choices"
        :key="elt.id"
      >
        {{ elt.name }}
      </b-form-select-option>
    </b-form-select>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeChoice } from '@/types/base'

export default {
  name: 'SeanceTypeSelector',
  mixins: [BackendMixin],
  props: {
    initialId: {
      type: [String, Number],
      defaultValue: 0,
    },
    seanceTypes: Array,
  },
  data() {
    return {
      choice: null,
      choices: [],
    }
  },
  created() {
    this.initSeanceCategories()
  },
  watch: {
    seanceTypes: function() {
      this.initSeanceCategories()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async initSeanceCategories() {
      const choices = []
      let hasExtra = false
      let hasSchool = false
      for (const seanceType of this.seanceTypes) {
        if (seanceType.schoolDay) {
          hasSchool = true
        } else {
          hasExtra = true
        }
      }
      if (hasSchool && hasExtra) {
        choices.push(makeChoice({ id: 0, name: 'Tout', }))
      }
      if (hasSchool) {
        choices.push(makeChoice({ id: 1, name: 'Périscolaire', }))
      }
      if (hasExtra) {
        choices.push(makeChoice({ id: 2, name: 'Extra-scolaire', }))
      }
      this.choices = choices
      if (this.choices.length >= 1) {
        this.choice = this.choices[0]
        this.$emit('change', { category: this.choice, })
      }
    },
    onChanged() {
      this.$emit('change', { category: this.choice, })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
