import { render, staticRenderFns } from "./SeanceCategorySelector.vue?vue&type=template&id=0264b578&scoped=true&"
import script from "./SeanceCategorySelector.vue?vue&type=script&lang=js&"
export * from "./SeanceCategorySelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0264b578",
  null
  
)

export default component.exports