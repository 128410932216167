<template>
  <div class="youth-home-stats" v-if="hasPerm('reports.view_')">
    <page-header
      title="Liste des inscriptions AEEH"
      icon="fas fa-table-cells"
      :links="getLinks()"
    >
    </page-header>
    <div class="handicap-report">
      <b-row>
        <b-col cols="12">
          <loading-gif :loading-name="statsLoading"></loading-gif>
          <b-row v-show="!isLoading(statsLoading) && showContent && !generateMode">
            <b-col cols="9">
              <date-range-selector @change="onDateRangeChanged" civil-first></date-range-selector>
            </b-col>
            <b-col cols="3">
              <b-form-group
                id="youth-homes-select-group"
                label-for="youth-homes"
              >
                <youth-home-selector
                  @change="onYouthHomeChanged($event.youthHome)"
                  no-year
                >
                </youth-home-selector>
              </b-form-group>
              <b-form-group
                id="seance-categories-select-group"
                label-for="seance-categories"
              >
                <seance-category-selector
                  :seance-types="allSeanceTypes"
                  @change="onSeanceCategoryChanged($event.category)"
                >
                </seance-category-selector>
              </b-form-group>
              <b-form-group
                id="seance-types-select-group"
                label-for="seance-types"
              >
                <seance-type-selector
                  :category="categoryId"
                  @init="onSeanceTypesLoaded($event.seanceTypes)"
                  @change="onSeanceTypeChanged($event.seanceType)"
                >
                </seance-type-selector>
              </b-form-group>
              <div class="btn-holder">
                <a class="btn btn-primary btn-block" @click.prevent="loadData()" :class="{ disabled: !isLoadStatsActive, }">
                  Rafraîchir
                </a>
              </div>
            </b-col>
          </b-row>
          <b-row v-show="!isLoading(statsLoading) && showContent && !generateMode">
            <b-col>
              <div class="filter-bar">
                <b-form-checkbox
                  id="onlyHandicapWithDoc"
                  v-model="onlyHandicapWithDoc"
                >
                  Seulement les enfants handicapés avec attestation CAF ou CDAPH vérifiée
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <generate-youth-home-stats
                v-show="!isLoading(statsLoading)"
                :start-date="startDate"
                :end-date="endDate"
                @generateMode="generateMode = $event.mode"
                @generating="disablePage"
                @generated="reloadPage"
              >
              </generate-youth-home-stats>
            </b-col>
          </b-row>
          <div v-if="!isLoading(statsLoading) && !loaded" class="empty-text">
            Cliquez sur Rafraîchir pour afficher les données
          </div>
          <div v-if="!isLoading(statsLoading) && showContent && loaded" ref="printMe">
            <b-row >
              <b-col cols="12">
                <div class="stats" ref="excelMe">
                  <table class="table table-full table-bordered table-striped small-text">
                    <tbody>
                      <tr>
                        <th>Nom</th>
                        <th>Né(e) le</th>
                        <th>&nbsp;</th>
                        <th style="max-width: 100px;">Attestation Caf ou CDAPH</th>
                        <th class="text-center">Durée réalisée (en h)</th>
                        <th class="text-center">Durée facturée (en h)</th>
                        <th class="text-center">Inscrit du</th>
                        <th class="text-center">au</th>
                        <th class="text-center">AEEH du</th>
                        <th class="text-center">au</th>
                      </tr>
                      <tr v-if="items.length === 0">
                        <td colspan="5" class="text-center">Aucune inscription AEEH sur la période</td>
                      </tr>
                      <tr  v-for="item of items" :key="item.id">
                        <th>
                          <span v-if="item.archived">{{ item.name }}</span>
                          <span v-else>
                            <a href @click.prevent="showIndividualSidebar(item.individual)">
                              {{ item.name }}
                            </a>
                          </span>
                        </th>
                        <td>{{ item.birthDate | dateToString }}</td>
                        <td>
                          <div v-for="entity of item.entities" :key="entity.id">
                            <span v-if="entity.socialRegime">
                              {{ entity.socialRegime.name }}
                            </span>
                            {{ entity.cafNumber }}
                          </div>
                        </td>
                        <td>
                          {{ item.handicapDocument | yesno }}
                        </td>
                        <td class="number">{{ item.realDuration}}</td>
                        <td class="number">{{ item.paidDuration}}</td>
                        <td class="text-center">{{ item.minDate | dateToString }}</td>
                        <td class="text-center">{{ item.maxDate | dateToString }}</td>
                        <td class="text-center">{{ item.handicapHelpStartsOn | dateToString }}</td>
                        <td class="text-center">{{ item.handicapHelpEndsOn | dateToString }}</td>
                      </tr>
                      <tr v-if="items.length">
                        <td colspan="4"></td>
                        <th class="number">{{ sumRealDuration}}</th>
                        <th class="number">{{ sumPaidDuration}}</th>
                        <td colspan="4"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import GenerateYouthHomeStats from '@/components/Stats/GenerateYouthHomeStats.vue'
import DateRangeSelector from '@/components/DateRange/DateRangeSelector.vue'
import YouthHomeSelector from '@/components/Seances/YouthHomeSelector.vue'
import SeanceCategorySelector from '@/components/Seances/SeanceCategorySelector.vue'
import SeanceTypeSelector from '@/components/Seances/SeanceTypeSelector.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeIndividual } from '@/types/people'
import { BackendApi, openDocument } from '@/utils/http'
import moment from 'moment'
import { makeSocialRegime } from '@/types/families'
import { sum } from '@/utils/math'

export default {
  name: 'handicap-reports',
  mixins: [BackendMixin],
  components: {
    SeanceCategorySelector,
    SeanceTypeSelector,
    YouthHomeSelector,
    LoadingGif,
    PageHeader,
    GenerateYouthHomeStats,
    DateRangeSelector,
  },
  props: {
  },
  data() {
    return {
      statsLoading: 'handicap-reports',
      disabled: true,
      showContent: true,
      generateMode: false,
      startDate: null,
      endDate: null,
      selectedYouthHome: null,
      selectedSeanceType: null,
      items: [],
      loaded: false,
      socialRegimes: new Map(),
      allSeanceTypes: [],
      categoryId: 0,
      onlyHandicapWithDoc: false,
    }
  },
  computed: {
    isLoadStatsActive() {
      return this.startDate && this.endDate
    },
    sumRealDuration() {
      return sum(this.items.map(item => item.realDuration))
    },
    sumPaidDuration() {
      return sum(this.items.map(item => item.paidDuration))
    },
  },
  mounted() {
    this.loadSocialRegimes()
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadData() {
      this.startLoading(this.loadingName)
      await this.loadStats(this.detail)
      this.endLoading(this.loadingName)
    },
    async loadSocialRegimes() {
      let backendApi = new BackendApi('get', '/api/families/social-regimes/')
      try {
        let resp = await backendApi.callApi()
        let socialRegimes = new Map()
        for (const elt of resp.data) {
          socialRegimes.set(elt.id, makeSocialRegime(elt))
        }
        this.socialRegimes = socialRegimes
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadStats() {
      if (!this.isLoadStatsActive) {
        return
      }
      this.loaded = false
      this.startDataDate = this.startDate
      this.endDataDate = this.endDate
      let url = '/reports/api/handicap-report/?start_date=' + this.startDate + '&end_date=' + this.endDate
      if (this.selectedYouthHome && this.selectedYouthHome.number) {
        url += '&youth_home=' + this.selectedYouthHome.number
      }
      if (this.selectedSeanceType && this.selectedSeanceType.id) {
        url += '&seance_type=' + this.selectedSeanceType.id
      }
      if (this.categoryId) {
        url += '&seance_category=' + this.categoryId
      }
      if (this.onlyHandicapWithDoc) {
        url += '&only_handicap_with_doc=1'
      }
      const backendApi = new BackendApi('get', url)
      const items = []
      try {
        const resp = await backendApi.callApi()
        for (const elt of resp.data) {
          items.push(
            {
              id: elt['individual_id'],
              individual: makeIndividual({ id: elt['individual_id'], }),
              name: elt['individual_name'],
              paidDuration: elt['paid_duration_sum'],
              realDuration: elt['real_duration_sum'],
              minDate: elt['min_date'],
              maxDate: elt['max_date'],
              handicapHelpStartsOn: elt['handicap_help_starts_on'],
              handicapHelpEndsOn: elt['handicap_help_ends_on'],
              handicapDocument: elt['handicap_document'],
              birthDate: elt['individual_birth_date'],
              archived: elt['archived'],
              entities: elt['entities'].map(
                sub => {
                  return {
                    id: sub['entity'] || 0,
                    socialRegime: this.socialRegimes.get(sub['social_regime']) || null,
                    cafNumber: sub['caf_number'] || '',
                  }
                }
              ),
            }
          )
        }
        this.loaded = true
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.items = items
    },
    getLinks() {
      const isDisabled = this.isLoading(this.statsLoading) || !this.loaded
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: isDisabled ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: isDisabled ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'liste-aeeh-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent, 'dossier_caf')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'liste-aeeh-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onDateRangeChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
      this.loaded = false
    },
    disablePage() {
      this.showContent = false
    },
    reloadPage() {
      this.showContent = true
      this.loadData()
    },
    onYouthHomeChanged(youthHome) {
      this.selectedYouthHome = youthHome
    },
    onSeanceTypeChanged(seanceType) {
      this.selectedSeanceType = seanceType
    },
    onSeanceTypesLoaded(seanceTypes) {
      this.allSeanceTypes = seanceTypes
    },
    onSeanceCategoryChanged(category) {
      this.categoryId = category ? category.id : 0
    },
  },
}
</script>

<style scoped lang="less">
.number {
  text-align: right;
}
</style>
