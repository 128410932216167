<template>
  <div>
    <b-form-select
      id="youth-homes-select"
      v-model="youthHome"
      @change="youthHomeChanged()"
    >
      <b-form-select-option
        :value="youthHome"
        v-for="youthHome in youthHomes"
        :key="getKey(youthHome)"
      >
        {{ youthHome.name }} <span v-if="youthHome.schoolYear.id && !noYear"> - {{ youthHome.schoolYear.name }}</span>
      </b-form-select-option>
    </b-form-select>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeYouthHome } from '@/types/youth'
import { BackendApi } from '@/utils/http'

export default {
  name: 'YouthHomeSelector',
  mixins: [BackendMixin],
  props: {
    initialId: {
      type: [String, Number],
      defaultValue: 0,
    },
    noYear: {
      type: Boolean,
      defaultValue: false,
    },
  },
  data() {
    return {
      youthHome: null,
      youthHomes: [],
    }
  },
  created() {
    this.loadYouthHomes()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    getKey(elt) {
      if (this.noYear) {
        return elt.number
      } else {
        return elt.id
      }
    },
    async loadYouthHomes() {
      const loadingName = 'youthHome'
      this.startLoading(loadingName)
      let url = '/api/youth/youth-homes/?active=1'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        let all = [makeYouthHome({ id: 0, name: 'Tous les accueils', })]
        let youthHomes = resp.data.map(elt => makeYouthHome(elt))
        if (this.noYear) {
          this.youthHomes = all
          // regarde si ce n° n'est pas déjà dans la list
          const numbers = this.youthHomes.map(elt => elt.id)
          for (const elt of youthHomes) {
            if (numbers.indexOf(elt.number) < 0) {
              this.youthHomes.push(elt)
              numbers.push(elt.number)
            }
          }
        } else {
          this.youthHomes = all.concat(youthHomes)
        }
        this.youthHomesLoaded()
        if (this.initialId) {
          const initial = this.youthHomes.filter(elt => elt.id === +this.initialId)
          if (initial.length) {
            this.youthHome = initial[0]
          } else {
            this.youthHome = all[0]
          }
        } else {
          this.youthHome = all[0]
        }
        this.$emit('init', { youthHome: this.youthHome, })
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
      this.endLoading(loadingName)
    },
    youthHomeChanged() {
      this.$emit('change', { youthHome: this.youthHome, })
    },
    youthHomesLoaded() {
      this.$emit('loaded', { youthHomes: this.youthHomes, })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
