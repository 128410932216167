<template>
  <div v-if="dateRange" class="generate-youth-home-stats">
    <b-row v-if="!generateMode">
      <b-col cols="10">
        <div class="date-range-info">
          <div v-if="startDataDate">
            {{ dateRange }} : Des données existent du {{ startDataDate | dateToString }}
            au {{ endDataDate | dateToString }}
          </div>
          <div v-else>
            {{ dateRange }} : Aucune donnée existante
          </div>
        </div>
      </b-col>
      <b-col cols="2">
        <a class="btn btn-secondary btn-block" @click.prevent="enterGenerateMode()">
          Générer
        </a>
      </b-col>
    </b-row>
    <div v-if="generateMode">
      <b-row>
        <b-col cols="4">
          <b-form-group
            id="start-date-group"
            label="Date de début:"
            label-for="start-date"
            description=""
          >
            <b-form-input
              id="start-date"
              v-model="startGenerateDate"
              type="date"
              :disabled="generateInProgress"
              :min="minDate"
              :max="maxDate"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            id="end-date-group"
            label="Date de fin:"
            label-for="end-date"
            description=""
          >
            <b-form-input
              id="end-date"
              v-model="endGenerateDate"
              type="date"
              :disabled="generateInProgress"
              :min="minDate"
              :max="maxDate"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <div class="generate-form-button">
            <a class="btn btn-secondary btn-block"
               @click.prevent="cancelGeneration()"
               :class="{ disabled: generateCancelled, }"
            >
              <span v-if="generateCancelled">Annulation</span>
              <span v-else>Annuler</span>
            </a>
          </div>
        </b-col>
        <b-col cols="2">
          <div class="generate-form-button">
            <a class="btn btn-primary btn-block"
               @click.prevent="doGenerate()"
               :class="{ disabled: generateInProgress || !isValid || hasGaps, }"
            >
              Lancer
            </a>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="warning-text" style="margin: 10px 0;" v-if="willOverwriteData">
            <i class="fa fa-warning"></i> Des données seront supprimées et remplacées.
          </div>
          <div class="error-text" style="margin: 10px 0;" v-if="!isValid">
            <i class="fa fa-warning"></i> Les dates ne sont pas valides
          </div>
          <div class="error-text" style="margin: 10px 0;" v-if="hasGaps">
            <i class="fa fa-warning"></i> Les dates peuvent créer des trous dans les données
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="10">
          <b-progress :max="nbDays" height="2rem" v-if="generateInProgress">
            <b-progress-bar :value="daysDone">
            </b-progress-bar>
          </b-progress>
        </b-col>
        <b-col cols="2" class="text-right">
          <b><span :title="progressDetail">{{ progressPercentage }}</span></b>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'

export default {
  name: 'GenerateYouthHomeStats',
  mixins: [BackendMixin],
  props: {
    startDate: String,
    endDate: String,
  },
  data() {
    return {
      minDate: '',
      maxDate: '',
      generateMode: false,
      generateInProgress: false,
      generateCancelled: false,
      startGenerateDate: '',
      endGenerateDate: '',
      startDataDate: '',
      endDataDate: '',
      nbDays: 0,
      daysDone: 0,
    }
  },
  watch: {
    startDate: function() { this.loadLimits() },
    endDate: function() { this.loadLimits() },
    generateMode: function() { this.$emit('generateMode', { mode: this.generateMode, }) },
  },
  computed: {
    dateRange() {
      if (this.startDate && this.endDate) {
        const startString = moment(this.startDate).format('DD/MM/YYYY')
        const endString = moment(this.endDate).format('DD/MM/YYYY')
        return 'Du ' + startString + ' au ' + endString
      }
      return ''
    },
    dateRangeValue() {
      if (this.startDate && this.endDate) {
        const startString = moment(this.startDate).format('YYYY-MM-DD')
        const endString = moment(this.endDate).format('YYYY-MM-DD')
        return '' + startString + '/' + endString
      }
      return ''
    },
    willOverwriteData() {
      if (this.startGenerateDate && this.endGenerateDate && this.startDataDate) {
        const startDate = moment(this.startGenerateDate)
        const endDate = moment(this.endGenerateDate)
        const startLimit = moment(this.startDataDate)
        const endLimit = moment(this.endDataDate)
        return (
          ((startDate >= startLimit) && (startDate <= endLimit)) ||
          ((endDate >= startLimit) && (endDate <= endLimit))
        )
      }
      return false
    },
    isValid() {
      if (this.startGenerateDate && this.endGenerateDate) {
        const startDate = moment(this.startGenerateDate)
        const endDate = moment(this.endGenerateDate)
        return endDate.diff(startDate) >= 0
      }
      return false
    },
    hasGaps() {
      const startDate = moment(this.startGenerateDate)
      const endDate = moment(this.endGenerateDate)
      const limStartDate = moment(this.startDataDate)
      const limEndDate = moment(this.endDataDate).add(1, 'day')

      // Il faut éviter les trous:
      // Si une date de fin de génération est supérieure à la date de fin actuelle
      // alors la date de début de génération est au moins antérieure à la date de fin actuelle
      if ((endDate > limEndDate) && (startDate > limEndDate)) {
        return true
      }

      // Si une date de début de génération est inférieure à la date de début actuelle
      // alors la date de fin de génération est au moins postérieure à la date de fin actuelle.
      if ((startDate < limStartDate) && (endDate < limStartDate)) {
        return true
      }

      return false
    },
    progressPercentage() {
      if (this.nbDays) {
        const value = Math.round(100 * this.daysDone / this.nbDays)
        return '' + value + '%'
      }
      return ''
    },
    progressDetail() {
      return '' + this.daysDone + '/' + this.nbDays
    },
  },
  created() {
    this.loadLimits()
  },
  methods: {
    ...mapActions(['addError', 'addWarning']),
    enterGenerateMode() {
      this.generateMode = true
      if (this.endDataDate) {
        this.startGenerateDate = moment(this.endDataDate).add(1, 'day').format('YYYY-MM-DD')
      } else {
        this.startGenerateDate = this.minDate
      }
      this.endGenerateDate = this.maxDate
    },
    async loadLimits() {
      if (this.dateRangeValue) {
        const url = '/reports/api/inscription-archives/' + this.dateRangeValue + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.startDataDate = resp.data['min_date'] || ''
          this.endDataDate = resp.data['max_date'] || ''
          this.minDate = moment(this.startDate).format('YYYY-MM-DD')
          this.maxDate = moment(this.endDate).format('YYYY-MM-DD')
          if (this.endDataDate) {
            this.startGenerateDate = moment(this.endDataDate).add(1, 'day').format('YYYY-MM-DD')
          } else {
            this.startGenerateDate = this.minDate
          }
          this.endGenerateDate = this.maxDate
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
      }
    },
    cancelGeneration() {
      if (this.generateInProgress) {
        this.generateCancelled = true
      } else {
        this.generateMode = false
      }
    },
    async doGenerate() {
      this.generateInProgress = true
      this.generateCancelled = false
      this.$emit('generating')
      this.daysDone = 0
      this.nbDays = moment(this.endGenerateDate).diff(moment(this.startGenerateDate), 'days') + 1
      if (this.dateRangeValue) {
        const url = '/reports/api/inscription-archives/' + this.dateRangeValue + '/'
        const backendApi = new BackendApi('post', url)
        let currentDate = moment(this.startGenerateDate)
        while (this.daysDone < this.nbDays) {
          try {
            let data = { date: currentDate.format('YYYY-MM-DD'), }
            await backendApi.callApi(data)
            currentDate = currentDate.add(1, 'day')
            this.daysDone += 1
            if (this.generateCancelled) {
              await this.addWarning('La génération a été interrompue')
              break
            }
          } catch (err) {
            this.addError(this.getErrorText(err))
            break
          }
        }
      }
      await this.loadLimits()
      this.nbDays = 0
      this.daysDone = 0
      this.generateInProgress = false
      this.generateMode = false
      this.$emit('generated')
    },
  },
}
</script>
<style scoped lang="less">
.generate-youth-home-stats {
  background: #e0e0e0;
  padding: 10px;
  font-weight: bold;
  margin-bottom: 20px;
}
.generate-form-button {
  margin-top: 30px;
}
</style>
