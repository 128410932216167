<template>
  <div>
    <b-form-select
      id="seance-type-select"
      v-model="seanceType"
      @change="onChanged()"
    >
      <b-form-select-option
        :value="elt"
        v-for="elt in visibleSeanceTypes"
        :key="elt.id"
      >
        {{ elt.name }}
      </b-form-select-option>
    </b-form-select>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeSeanceType } from '@/types/youth'
import { BackendApi } from '@/utils/http'

export default {
  name: 'SeanceTypeSelector',
  mixins: [BackendMixin],
  props: {
    initialId: {
      type: [String, Number],
      defaultValue: 0,
    },
    category: {
      type: [String, Number],
      defaultValue: 0,
    },
  },
  data() {
    return {
      seanceType: null,
      seanceTypes: [],
    }
  },
  created() {
    this.loadSeanceType()
  },
  computed: {
    visibleSeanceTypes() {
      if (this.category) {
        if (this.category === 1) {
          return this.seanceTypes.filter(elt => (elt.id === 0) || elt.schoolDay)
        } else if (this.category === 2) {
          return this.seanceTypes.filter(elt => (elt.id === 0) || !elt.schoolDay)
        }
      }
      return this.seanceTypes
    },
  },
  watch: {
    category: function() {
      if (this.category) {
        this.seanceType = this.seanceTypes[0]
        this.$emit('change', { seanceType: this.seanceType, })
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadSeanceType() {
      const loadingName = 'youthHome'
      this.startLoading(loadingName)
      let url = '/api/youth/seance-types/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        let all = [makeSeanceType({ id: 0, name: 'Toutes les séances', })]
        let seanceTypes = resp.data.map(elt => makeSeanceType(elt))
        this.seanceTypes = all.concat(seanceTypes)
        if (this.initialId) {
          const initial = this.seanceTypes.filter(elt => elt.id === +this.initialId)
          if (initial.length) {
            this.seanceType = initial[0]
          } else {
            this.seanceType = all[0]
          }
        } else {
          this.seanceType = all[0]
        }
        this.$emit('init', { seanceType: this.seanceType, seanceTypes: seanceTypes, })
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(loadingName)
    },
    onChanged() {
      this.$emit('change', { seanceType: this.seanceType, })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
